import React, {useState,useEffect,useRef} from 'react';

import ReactGA from 'react-ga4';
import { Transition } from 'react-transition-group';
import { useThrottle } from '@react-hook/throttle';
import styles from './App.module.scss';
import './Fades.scss';

import message from "./imgs/message.png";
import blender_fund from "./imgs/blender_fund.png";
// import france_relance from "./imgs/france_relance.png";
import youtube from "./imgs/youtube.png";
import logo from "./imgs/logo.svg";
import logo_youtube from "./imgs/logo_youtube.svg";
import logo_github from "./imgs/logo_github.svg";

ReactGA.initialize('UA-18690263-13');
var has_focus = true;
window.setInterval(function(){
	if(has_focus){
		ReactGA.event({
			category: 'general',
			action: 'read-update'
		});
	}
},13000);
window.onblur = function() { has_focus = false; }
window.onfocus = function() { has_focus = true; }
ReactGA.pageview('/');

function Work(props){

	const [relative_hover,setRelativeHover] = useThrottle([0,0],20,true);
	const workEl = useRef(null);

	const oMM = (e)=>{
		if(workEl.current){
			const rect = workEl.current.getBoundingClientRect();
			const x = e.clientX - (rect.left+rect.width/2);
			const y = e.clientY - (rect.top+rect.height/2);
			setRelativeHover([x,-y]);
		}
	};

	return (
		<div ref={workEl} className={styles.work} onMouseOver={props.onMouseOver} onMouseLeave={props.onMouseLeave} onClick={()=>{
			window.open(props.work.url,'_blank');
			ReactGA.event({
				category: 'interaction',
				action: props.work.url
			});
			props.onMouseLeave&&props.onMouseLeave();
		}} onMouseMove={oMM}>
			<div className={styles.work_block_container} style={{
				transform: (props.rotate)?`perspective(800px) rotateY(${relative_hover[0]/9}deg) rotateX(${relative_hover[1]/4}deg) translateZ(10px)`:'none'
			}}>
				<p>{props.work.title}</p>

				<div className={styles.work_block_ratiocontainer}>
					{!props.work.vid&&<img className={styles.work_block_image} src={`./captures/${props.work.img}`} alt={props.work.title} loading="lazy" />}
					{props.work.vid&&<video src={`./captures/${props.work.vid}`} muted={true} autoPlay={true} playsInline={true} loop={true} className={styles.work_block_video}></video>}
				</div>


				<div className={styles.work_block}>
					<div className={styles.work_block_inner}>
						<div className={styles.work_block_glass} style={{
							opacity: (props.rotate)?'1':'0',
							transform: `rotate(${relative_hover[0]/20-20}deg) translateY(${relative_hover[1]/8}px)`,
						}}></div>
					</div>
					<div className={styles.work_block_triangle}>
					</div>
				</div>
			</div>
		</div>
	);
}


function App() {

	const referrer = ''+document.referrer+window.location.hash;
	// var localmode = false;
	// if(referrer.includes('pagesjaunes')){
	// 	localmode = true;
	// }

	const services = ["Conseil et développement","Conception de sites web","Contenus 3D","SEO & cost-kill publicitaire","Applications sur mesure"];
	var callout = "au rapport technicité/prix imbattable ?";

	const [current_service,setCurrentService] = useState((referrer.includes('youtube')||referrer.includes('film'))?2:0);
	const service_to_show = services[current_service%services.length];
	const [displayed_service,setDisplayedService] = useState(service_to_show);
	const [first_load,setFirstLoad] = useState(0);
	const [hovered,setHovered] = useState(false);

	const works = [
		// {
		// 	title: 'Site STM Modules',
		// 	url: 'https://www.stm-modules.fr/',
		// 	img: 'stm.png'
		// },
		// {
		// 	title: 'Configurateur 3D SCREB',
		// 	url: 'https://screb.com/fr/configurateur-3d/',
		// 	img: 'screb_configurateur3d.png'
		// },
		{
			title: 'Site et solutions 3D SCREB',
			url: 'https://screb.com/',
			img: 'screb_site.png'
		},
		{
			title: 'Logiciel web AutoBuild',
			url: 'https://autobuild.nmoreaux.com/',
			img: 'autobuild.png'
		},
		{
			title: 'Film 3D montage bâtiment',
			url: 'https://www.youtube.com/watch?v=g6sSbazsyLw',
			img: 'film3d.png',
			vid: 'film3d.mp4'
		},
		{
			title: 'Site et solutions 3D Abri-Tole.fr',
			url: 'https://www.abri-tole.fr/',
			img: 'abritole.png'
		},
		{
			title: 'Site TolesDiscount.fr',
			url: 'https://www.tolesdiscount.fr/',
			img: 'tolesdiscount.png'
		},
		{
			title: 'Calculateur NV65',
			url: 'https://nv65.nmoreaux.com/',
			img: 'nv65.png'
		}
		// {
		// 	title: 'Film 3D marketing Visionaute',
		// 	url: 'https://www.youtube.com/embed/rUJ-BAbdT8E?rel=0&playlist=rUJ-BAbdT8E&loop=1&showinfo=0&autoplay=1&mute=1&autohide=0&iv_load_policy=3',
		// 	img: 'visionaute.jpg'
		// },
	];
	// if(localmode){
	// 	works[1] = works[0];
	// 	works[0] = {
	// 		title: 'Mini-site Codisteel à 150€',
	// 		url: 'https://www.codisteel.fr/',
	// 		img: 'codisteel.jpg'
	// 	};
	// }

	useEffect(()=>{
		const i = setInterval(function () {
			if(first_load > 1){
				setCurrentService((c)=>{
					return c+1;
				});
			}
			setFirstLoad(first_load + 1);
		},3000);
		return ()=>{
			clearInterval(i);
		}
	},[first_load]);


	// Si vous voulez distancer la concurrence, Nikki Moreaux vous donnera des années d'avance

	return (
    <div>
		<div className={styles.header}>
			<div className={styles.container}>
				{/* <p>Vous souhaitez</p> */}
				{/* <p><strong>Nikki Moreaux</strong> EIRL</p> */}
				<img src={logo} width="270" height="46" alt="Nikki Moreaux EIRL"/>
				<p className={styles.header_service}>
					<Transition in={displayed_service === service_to_show} timeout={200} onExited={()=>{setDisplayedService(service_to_show)}}>
					{state => (<span className={'fade '+state}>
						{displayed_service}
					</span>)}
					</Transition>
				</p>
				{false&&<p>{callout}</p>}

				<a href="https://www.youtube.com/nikkimoreaux" target="_blank" rel="noopener noreferrer">
					<img src={youtube} width="345" height="66" alt="Youtube"/>
				</a>
			</div>
		</div>
		<div className={styles.works+' '+((hovered!==false)?styles.works_hovered:'')}>
			<p className={styles.works_like}>références non exhaustives :</p>
			<div className={styles.container_special}>
				<div className={styles.works_grid}>
					{works.map((w,i)=>(<Work key={i} work={w} onMouseOver={()=>{
						setHovered(i);
					}} onMouseLeave={()=>{
						setHovered(false);
					}} rotate={hovered===i} />))}
				</div>
			</div>
		</div>
		<div className={styles.contact}>
			<div className={styles.container}>


				<h1>Contactez <strong>Nikki Moreaux</strong> EIRL</h1>
				<p>Conseil et développement</p>
				<p>Montpellier — France — Remote</p>
				<div className={styles.contact_btn}>
					<a href="mailto:contact@nmoreaux.com"><span></span>contact@nmoreaux.com</a>
				</div>
				<div className={styles.contact_btn}>
					<a href="tel:+33189168699">01 89 16 86 99</a>
				</div>
				<img src={message} width="500" height="60" alt="M. Moreaux répondra à toutes vos questions"/>
				
				<div className={styles.contact_btn}>
					<a target="_blank" href="./it_just_works.mp4">It just works!</a>
				</div>

				<div className={styles.contact_btn}>
					<a target="_blank" rel="noopener noreferrer" href="https://siteautop.fr/">Créez votre site gratuitement avec SiteAuTop.fr</a>
				</div>
				{false&&<a href="https://fund.blender.org/" target="_blank" rel="noopener noreferrer"><img src={blender_fund} width="128" height="128" alt="Membre du Blender Development Fund"/></a>}
				{false&&<p><strong>Membre du <a href="https://fund.blender.org/" target="_blank" rel="noopener noreferrer">Blender Development Fund</a></strong></p>}
			</div>
		</div>
		<div className={styles.footer}>
			<div className={styles.container}>
				<div className={styles.footer_parts}>
					<ul>
						<li>© NIKKI MOREAUX EIRL {(new Date().getFullYear())}</li>
						<li>SIREN 529547937</li>
						<li>TVA FR36529547937</li>
						<li><a href="./cgv" target="_blank">mentions légales & CGV</a></li>
					</ul>
					<ul>
						<li>
							<a href="https://www.youtube.com/nikkimoreaux" rel="noopener noreferrer" target="_blank">
								<img src={logo_youtube} alt="Profil YouTube"/>
							</a>
						</li>
						<li>
							<a href="https://github.com/nikkimoreaux" rel="noopener noreferrer" target="_blank">
								<img src={logo_github} alt="Profil GitHub"/>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
    </div>
	);
}

export default App;
